<template>
    <div class="login-wrap">
        <div class="login-container">
            <el-form
                label-position="left"
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="0px"
                class="demo-ruleForm"
            >
                <div class="logo-box">
                    <img src="../assets/images/logo.png" class="logo" alt="" />
                </div>
                <div class="title">
                    山东省商务厅
                    <br />
                    外贸运行监测系统
                </div>
                <el-form-item prop="username">
                    <el-input
                        type="text"
                        v-model="ruleForm.username"
                        auto-complete="off"
                        placeholder="账号"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item prop="password">
        <el-input
          type="password"
          v-model="ruleForm.password"
          auto-complete="off"
          placeholder="密码"
        ></el-input>
      </el-form-item> -->
                <el-row>
                    <el-col :span="16">
                        <el-form-item prop="code">
                            <el-input
                                type="text"
                                v-model="ruleForm.code"
                                auto-complete="off"
                                placeholder="验证码"
                                @keyup.enter.native="submitForm('ruleForm')"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="code-box">
                        <el-button
                            class="getCaptcha"
                            tabindex="-1"
                            type="info"
                            plain
                            :disabled="state.smsSendBtn"
                            @click.native="getCaptcha"
                            v-text="
                                (!state.smsSendBtn && '获取验证码') ||
                                state.time + ' s'
                            "
                        >
                        </el-button>
                    </el-col>
                </el-row>
                <!-- <el-checkbox
        class="remember"
        v-model="rememberpwd"
      >记住密码</el-checkbox> -->
                <el-form-item style="width: 100%">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="submitForm('ruleForm')"
                        :loading="logining"
                        >登录</el-button
                    >
                </el-form-item>
            </el-form>
            <!-- 微信 -->
            <!-- <div class="wx">
      <div class="wx-title">微信登录</div>
      <div class="wx-code"></div>
      <div class="wx-tip">
        使用微信扫一扫登录
        <br/>"山东省商务厅调研问卷系统"
      </div>
    </div> -->
        </div>
    </div>
</template>
<script>
import { login, smsApi } from '../api/userMG'
import { setCookie, getCookie, delCookie, timeFix } from '../utils/util'
import { setToken, removeToken } from '../utils/auth'
// import md5 from 'js-md5'
const checkMobile = function (rule, value, callback) {
    const regMobile =
		/^(?:\+?86)?(13\d|14[579]|15\d|16\d|17\d|18\d|19\d)\d{8}$/
    if (!value) {
        callback(new Error('请输入手机号'))
    } else {
        if (regMobile.test(value)) {
            return callback()
        }
        callback(new Error('请输入正确的手机号'))
    }
}
export default {
    name: 'login',

    data() {
        return {
            //定义loading默认为false
            logining: false,
            // 记住密码
            rememberpwd: false,
            ruleForm: {
                //username和password默认为空
                // username: '17861721358',
                username: '',
                password: '',
                code: '',
                randomStr: '',
                codeimg: '',
            },
            state: {
                time: 60,
                loginBtn: false,
                // login type: 0 email, 1 username, 2 telephone
                loginType: 0,
                smsSendBtn: false,
            },
            //rules前端验证
            rules: {
                username: [
                    {
                        validator: checkMobile,
                        trigger: 'blur',
                    },
                ],
                // password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                code: [
                    {
                        required: true,
                        message: '请输入验证码',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    // 创建完毕状态(里面是操作)
    created() {
        removeToken()
        // this.$message({
        //   message: '账号密码及验证码不为空即可',
        //   type: 'success'
        // })
        // 获取图形验证码
        // this.getcode()
        // 获取存在本地的用户名密码
        // this.getuserpwd()
    },
    // 里面的函数只有调用才会执行
    methods: {
        // 获取用户名密码
        getuserpwd() {
            if (getCookie('user') != '' && getCookie('pwd') != '') {
                this.ruleForm.username = getCookie('user')
                this.ruleForm.password = getCookie('pwd')
                this.rememberpwd = true
            }
        },
        // 点击获取验证码
        getCaptcha(e) {
            e.preventDefault()
            // const hide = this.$message.warning(
            //     '验证码发送中..',
            //     [0]
            // )
            // setTimeout(hide, 2500)
          
            // const { form: { validateFields } } = this

            this.$refs['ruleForm'].validateField(
                ['username'],
                (err, values) => {
                    console.log('err', err)
                    console.log('values', values)
                    if (!values) {
                        if (this.ruleForm.username) {
                            // this.$message({
                            //     showClose: true,
                            //     message: '验证码发送中..',
                            //     type: 'warning'
                            // });
                            const interval = window.setInterval(() => {
                                if (this.state.time-- <= 0) {
                                    this.state.time = 60
                                    this.state.smsSendBtn = false
                                    window.clearInterval(interval)
                                }
                            }, 1000)
                            var params = {
                                tel: this.ruleForm.username,
                                usage: 'login',
                            }
                            smsApi(params)
                                .then((res) => {
                                    if (res.code !== 200) {
                                        this.$notify({
                                            title: '错误',
                                            message: res.msg,
                                            type: 'error',
                                        })
                                        return
                                    }
                                    this.state.smsSendBtn = true
                                    this.$message({
                                        showClose: true,
                                        message: '验证码获取成功',
                                        type: 'success'
                                    });
                                })
                                .catch((err) => {
                                    clearInterval(interval)
                                    this.state.time = 60
                                    this.state.smsSendBtn = false
                                    this.requestFailed(err)
                                })
                        }
                    }
                }
            )

            // })
        },
        // 获取短信验证码
        // async smsFun(){
        //   var params={
        //     tel:this.ruleForm.username,
        //     usage:'login'
        //   }
        //   var res = await smsApi(params)
        //   console.log('res', res);
        // },
        //获取info列表
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.logining = true
                    // 注释 this.ruleForm
                    var params = {
                        phoneNumber: this.ruleForm.username,
                        code: this.ruleForm.code,
                    }
                    login(params)
                        .then((res) => {
                            if (res.code === 200) {
                                this.$store.dispatch('permission/generateRoutes', res.data.adminUser, { root: true })
                                if (this.rememberpwd) {
                                    //保存帐号到cookie，有效期7天
                                    setCookie('user', this.ruleForm.username, 7)
                                    //保存密码到cookie，有效期7天
                                    setCookie('pwd', this.ruleForm.password, 7)
                                } else {
                                    delCookie('user')
                                    delCookie('pwd')
                                }

                                // 缓存token
                                var token = 'Bearer ' + res.data.token
                                localStorage.setItem('logintoken', token)
                                localStorage.setItem(
                                    'adminUser',
                                    JSON.stringify(res.data.adminUser)
                                )
                                setToken(token)
                                // 缓存用户个人信息
                               
                                localStorage.setItem(
                                    'userdata',
                                    JSON.stringify(res.data)
                                )
                                // this.$store.commit('login', 'true')
                                // this.$router.push('/')
                                window.location.href = this.redirect || '/' // window.location.href是动态跳转，会刷新页面
                                //如果请求成功就让他2秒跳转路由
                                setTimeout(() => {
                                    this.logining = false
                                    this.$notify({
                                        title: '欢迎',
                                        message: `${timeFix()}，欢迎回来`,
                                        type: 'success',
                                    })
                                }, 1000)
                            } else {
                                this.$message.error(res.msg)
                                this.logining = false
                                return false
                            }
                        })
                        .catch(() => {
                            // this.$message.error('请输入用户名密码！')
                            this.logining = false
                            return false
                        })
                } else {
                    // 获取图形验证码
                    // this.getcode()
                    this.$message.error('请输入账号验证码！')
                    this.logining = false
                    return false
                }
            })
        },
    },
}
</script>

<style scoped>
.login-wrap {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 127px;
    background-image: url('../assets/images/index-bg.jpg');
    background-repeat: no-repeat;
    background-position: center right;
    /* background-size: 100%; */
    background-size: cover;
}
.login-container {
    border-radius: 10px;
    margin: 0px auto;
    width: 668px;
    padding: 62px 136px 37px 152px;
    background: #fff;
    border: 1px solid #eaeaea;
    text-align: left;
    box-sizing: border-box;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}
.logo-box {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.logo {
    width: 185px;
    height: 88px;
}
.title {
    width: 145px;
    height: 50px;
    margin: 10px auto 38px auto;
    text-align: center;
    color: #505458;
    font-size: 16px;
    font-weight: bold;
}
.remember {
    margin: 0px 0px 35px 0px;
}
.code-box {
    text-align: right;
}
.codeimg {
    height: 40px;
}
.getCaptcha {
    width: 90%;
}

/* 微信 */
.wx {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wx-title {
    font-size: 14px;
    font-weight: bold;
}
.wx-code {
    width: 174px;
    height: 174px;
    margin: 9px auto 13px auto;
    background-color: rgb(223, 223, 223);
}
.wx-tip {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}
</style>
